import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { Box, Button, FormControl, IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import QueueSelect2 from "../../components/QueueSelect/index2.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    marginBottom: 12,
    width: "100%",
    flexDirection: "column",
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Triagem = () => {
  const classes = useStyles();

  const [settings, setSettings] = useState([]);
  const [endTime, setEndTime] = useState("");
  const [inactivityTime, setInactivityTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [triageMessage, setTriageMessage] = useState("");
  const [outoOfService, setoutoOfService] = useState("");

  // Carregar as configurações ao montar o componente
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const result = await api.get("/triage");
        setSettings(result.data.options);
        setEndTime(result.data.config.endTime);
        setInactivityTime(result.data.config.inactivityTime);
        setStartTime(result.data.config.startTime);
        setTriageMessage(result.data.config.triageMessage);
        setoutoOfService(result.data.config.outOfServiceMessage);
      } catch (e) {
        toastError(e);
      }
    };
    fetchSettings();
  }, []);

  const handleSave = async () => {
    if (!endTime || !inactivityTime || !startTime || !triageMessage) {
      toast.error("Todos os campos obrigatórios devem ser preenchidos!");
      return;
    }

    if (!settings || settings.length === 0 || settings.some(option => !option.queueId || !option.description)) {
      toast.error("Todos os objetos nas opções devem ter Fila e Descrição!");
      return;
    }


    try {
      await api.post("/triage", {
        endTime,
        inactivityTime,
        startTime,
        triageMessage,
        outOfServiceMessage: outoOfService,
        options: settings,
      });
      toast.success("Configurações salvas com sucesso!");
    } catch (e) {
      toastError(e);
    }
  };

  // Função para adicionar uma nova opção de triagem
  const handleAddOption = () => {
    setSettings([...settings, { id: settings.length + 1, description: "", queueId: null }]);
  };

  // Função para remover uma opção de triagem
  const handleRemoveOption = (id) => {
    setSettings(settings.filter((option) => option.id !== id));
  };

  // Função para atualizar as opções de triagem
  const handleOptionChange = (id, field, value) => {
    console.log(id, field, value)
    setSettings(
      settings.map((option, index) =>
        index === id ? { ...option, [field]: value } : option
      )
    );
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="body2" gutterBottom>
            Configuração da Triagem Automática
          </Typography>
          <Box style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Tempo Inatividade (Minutos)"
              margin="dense"
              variant="outlined"
              type="number"
              fullWidth
              value={inactivityTime}
              onChange={(e) => setInactivityTime(e.target.value)}
            />
            <TextField
              label="Horário Inicial"
              margin="dense"
              variant="outlined"
              fullWidth
              style={{ marginLeft: "10px" }}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <TextField
              label="Horário Final"
              margin="dense"
              variant="outlined"
              fullWidth
              style={{ marginLeft: "10px" }}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </Box>
          <TextField
            label="Mensagem da Triagem"
            name="message"
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            rows={5}
            value={triageMessage}
            onChange={(e) => setTriageMessage(e.target.value)}
          />
          <TextField
            label="Mensagem Fora de Atendimento"
            name="message"
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            rows={5}
            value={outoOfService}
            onChange={(e) => setoutoOfService(e.target.value)}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" style={{ marginTop: "20px" }} gutterBottom>
              Opções
            </Typography>
            <Button
              variant="contained"
              style={{ background: "#9EBC0D", marginTop: "10px", color: "white" }}
              onClick={handleAddOption}
            >
              Adicionar Opção
            </Button>
          </Box>
          <Box>
            {settings.map((setting, index) => (
              <Box
                key={index}
                style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
              >
                <Typography
                  variant="body2"
                  style={{ marginTop: "20px", width: "5%", textAlign: "right" }}
                  gutterBottom
                >
                  {index + 1} -
                </Typography>
                <TextField
                  name="description"
                  type="text"
                  label="Descrição da opção"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  style={{ width: "80%", marginLeft: 6 }}
                  value={setting.description}
                  onChange={(e) =>
                    handleOptionChange(index, "description", e.target.value)
                  }
                />
                <QueueSelect2
                  selectedQueueId={setting.queueId}
                  onChange={(values) => handleOptionChange(index, "queueId", values)}
                />
                <IconButton
                  className={classes.settingOption}
                  variant="contained"
                  onClick={() => handleRemoveOption(index)}
                >
                  <CloseOutlined />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              style={{ background: "#9EBC0D", marginTop: "10px", color: "white" }}
              onClick={handleSave}
            >
              Salvar
            </Button>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Triagem;
