import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueSelect2 = ({ selectedQueueId, onChange }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	const [selectedQueue, setSelectedQueue] = useState(selectedQueueId || ""); // Definir valor inicial com fallback

	useEffect(() => {
		const fetchQueues = async () => {
			try {
				const { data } = await api.get("/queue");
				setQueues(data);
			} catch (err) {
				toastError(err);
			}
		};

		fetchQueues();
	}, []);

	// Atualizar quando o valor inicial mudar
	useEffect(() => {
		console.log(selectedQueueId)
		if (selectedQueueId) {
			setSelectedQueue(selectedQueueId);
		}
	}, [selectedQueueId]);

	const handleChange = (e) => {
		const newValue = e.target.value;
		setSelectedQueue(newValue);
		onChange(newValue);
	};

	return (
		<div style={{ marginLeft: 6, width: "200px" }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>Fila</InputLabel>
				<Select
					labelWidth={60}
					value={selectedQueue}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={(selected) => {
						const queue = queues.find((q) => q.id === selected);
						// Renderiza o Chip apenas se a fila for encontrada
						return queue ? (
							<Chip
								style={{ backgroundColor: queue.color, maxHeight: '15px' }}
								variant="outlined"
								label={queue.name}
								className={classes.chip}
								
							/>
						) : (
							<span>Selecione uma fila</span> // Mensagem padrão quando não há seleção
						);
					}}
				>
					{queues.map((queue) => (
						<MenuItem key={queue.id} value={queue.id}>
							{queue.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelect2;
